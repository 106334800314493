import { InlineWidget } from "react-calendly";


export default function CallInline() {
    return (
            <InlineWidget 
                url="https://calendly.com/inventuminternational/30min"
                utm={{salesforce_uuid: localStorage.getItem("gclid") || null}}
                />
    )
}